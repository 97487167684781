import { useEffect, useState } from 'react';
import striptags from 'striptags';
import TextField from '@mui/material/TextField';
const he = require("he")

export default function CaptionComponent ({YoutubeURL}: any) {
    const [data, setData] = useState("")
    
    function getVideoId(videoUrl: String) {
        if (videoUrl) {
            return videoUrl.split("v=")[1].split("&")[0];
        }
    }
    const regex = /({"captionTracks":.*isTranslatable":(true|false)}])/;
    const fetchData = async() => {
        const VideoID = getVideoId(YoutubeURL)


        
        
        
        // const result = await fetch(`https://youtube.com/watch?v=${VideoID}`) 
        //     .then((res) => res.text())
        
            const url = `https://youtube.com/watch?v=${VideoID}`
            const result = await fetch("/proxy/fetchData", {  //http://localhost:5000
                method: 'POST',
                body: JSON.stringify({
                    url: url
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.text())
            .then((res) => regex.exec(res))
            .then((res) => {
                if (res !== null){
                    const allLanguagesTranscripts = JSON.parse(res[0] + "}")["captionTracks"]
                    for (var i=0; i < allLanguagesTranscripts.length; i++){
                        if (allLanguagesTranscripts[i]["languageCode"] === "en") {
                            return JSON.parse(res[0] + "}")["captionTracks"][i]["baseUrl"]
                        }
                    }
                }
            })
          .catch((error) => {
            console.log(error);
          })
          return result
    };
    const lines = (captions: any) => {
        return captions
            .replace('<?xml version="1.0" encoding="utf-8" ?><transcript>', '')
            .replace('</transcript>', '')
            .split('</text>')
            .filter((line: any) => line && line.trim())
            .map((line: any) => {
            
            const htmlText = line
                .replace(/<text.+>/, '')
                .replace(/&amp;/gi, '&')
                .replace(/<\/?[^>]+(>|$)/g, '')
                .replace('\n', ' ');

            const decodedText = he.decode(htmlText);
            const text = striptags(decodedText);

            return {
                text
            };
    })};
    const fetchCaption = async(url: any) => {
        const result = await fetch("/proxy/fetchCaption", {  //http://localhost:5000
            method: 'POST',
            body: JSON.stringify({
                url: url
            }),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.text())
        // const result = await fetch(url).then(res => res.text()) 
        return result
    };

    const getAndProcess = async() => {
        const url = await fetchData()
        if (url === undefined) {
            setData("Transcript not available")
        } else {
            console.log(url)
            const caption = await fetchCaption(url)
            const captions_lines = await lines(caption) 
            var finalResult = "" 
            for (var i=0; i < captions_lines.length; i++){
                finalResult = finalResult + " " + captions_lines[i]["text"]         
            }
            setData(finalResult)            
        }

    }
    useEffect(() => {
        getAndProcess()
    }, [YoutubeURL])

    return(
        <div>
            <br />
            <TextField
              id="standard-multiline-flexible"
              label="Transcript"
              multiline
            //   maxRows={1000}
              rows={15}
              value={data}
              variant="standard"
              inputProps={
                { readOnly: true, }
              }
              sx={{ width: '75ch' }}
            />

        </div>
        // <p>{data}</p>
    )
}