import React from 'react';
import logo from './logo.svg';
import './App.css';
import FetchComponent from './Components/FetchComponent'
import VideoComponent from './Components/VideoComponent'
import { useEffect, useState } from 'react';
import CaptionComponent from './Components/CaptionComponent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function App() {
  const [youtubeURL, setYoutubeURL] = useState("")

  
  function validateYouTubeUrl() {   
        if (youtubeURL != undefined || youtubeURL != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = youtubeURL.match(regExp);
            if (match && match[2].length == 11) {
                console.log("Valid Youtube URL")
                return true
            }
            else {
                console.log("Not Valid Youtube URL, Please enter a valid Link")
                return false
            }
        }
        return false
  }
  return (
    <div className="App" style={{display: "flex", flexDirection: "column"}}>
      <FetchComponent onChangeURL={setYoutubeURL}/>
      {
        validateYouTubeUrl() === true
        ?
        <>
          <VideoComponent YoutubeURL={youtubeURL} />
          <CaptionComponent style={{flex: 1}} YoutubeURL={youtubeURL} sx={{ mt: 10 }} />      
          <footer style={{marginTop: "1em", marginBottom: "1em", bottom: "0", width: "100%"}}>
            Made with ❤️ by <a href='https://empirecaptions.com/'>Empire Caption Solutions</a> | <a href="https://documenter.getpostman.com/view/12259688/2s8YzRyNhG">API documentation</a> 
          </footer>
        </>
        :  
        <>
          <Typography variant="h6" color="red">
              Please Enter a Valid Youtube Video URL
          </Typography>
          <footer style={{marginTop: "1em", marginBottom: "1em", bottom: "0", position: "fixed", width: "100%"}}>
            Made with ❤️ by <a href='https://empirecaptions.com/'>Empire Caption Solutions</a> | <a href="https://documenter.getpostman.com/view/12259688/2s8YzRyNhG">API documentation</a>
            
          </footer>
        </>
      }
      
      
    </div>
  );
}

export default App;

