import YouTube, { YouTubeProps } from 'react-youtube';
// import { getSubtitles } from 'youtube-captions-scraper';
import axios from 'axios';
import { useEffect, useState } from 'react';
// import he from 'he';
import striptags from 'striptags';
import { EndOfLineState } from 'typescript';
const he = require("he")

export default function VideoComponent({YoutubeURL}: any) {    
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    function getVideoId(videoUrl: String) {
        if (videoUrl) {
            return videoUrl.split("v=")[1].split("&")[0];
        }
    }
    const opts: YouTubeProps['opts'] = {
        height: '270',
        width: '480',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            cc_load_policy: 1,
            cc_lang_pref: "en",
        },
    };

    return(
        <div>
            <YouTube videoId={getVideoId(YoutubeURL)} opts={opts} onReady={onPlayerReady} /> 
        </div>
    )
}